import { inject } from '@angular/core';
import { CollectionApiService } from '@app/shared/api/collection/collection-api.service';
import {
  CollectionOrgans,
  CollectionOrganSystem,
  CollectionPathology,
  CollectionPathologyType,
  CollectionUuids,
  PathologyStatusBasedCollection,
} from '@app/signal-store/collection/collection-state.interface';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { map, pipe, switchMap, tap } from 'rxjs';

type CollectionState = {
  collection: PathologyStatusBasedCollection;
  collectionUuids: CollectionUuids;
  isLoading: { collection: boolean; collectionUuids: boolean };
};

const initialState: CollectionState = {
  collection: {
    articles: null,
    bundles: null,
    organs: null,
    organSystems: null,
    pathologies: null,
    types: null,
  },
  collectionUuids: {
    articleUuids: null,
    bundleUuids: null,
    pathologyTypeUuids: null,
    pathologyUuids: null,
  },
  isLoading: { collection: false, collectionUuids: null },
};

export const CollectionStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, collectionApiService = inject(CollectionApiService)) => ({
    loadCollection: rxMethod<void>(
      pipe(
        tap(() =>
          patchState(store, {
            isLoading: { ...store.isLoading(), collection: true },
          })
        ),
        switchMap(() => {
          return collectionApiService.getAllItems$().pipe(
            map(collection => {
              const pathologies: CollectionPathology[] = [];
              const pathologyTypes: CollectionPathologyType[] = [];
              const organs: CollectionOrgans[] = [];
              const organSystems: CollectionOrganSystem[] = [];

              collection.pathologies.forEach(pathology => {
                if (pathology['pathology']) {
                  pathologies.push(pathology);
                } else {
                  organSystems.push(pathology);
                }
              });

              collection.types.forEach(pathologyType => {
                if (pathologyType.pathology) {
                  pathologyTypes.push(pathologyType);
                } else {
                  organs.push(pathologyType);
                }
              });

              return {
                articles: collection.articles,
                bundles: collection.bundles,
                pathologies: pathologies,
                types: pathologyTypes,
                organs: organs,
                organSystems: organSystems,
              };
            }),
            tapResponse({
              next: collection => patchState(store, { collection }),
              error: console.error,
              finalize: () =>
                patchState(store, {
                  isLoading: { ...store.isLoading(), collection: false },
                }),
            })
          );
        })
      )
    ),
    loadCollectionUuids: rxMethod<void>(
      pipe(
        tap(() =>
          patchState(store, {
            isLoading: { ...store.isLoading(), collectionUuids: true },
          })
        ),
        switchMap(() => {
          return collectionApiService.getAllItemsUuids$().pipe(
            tapResponse({
              next: collectionUuids => patchState(store, { collectionUuids }),
              error: console.error,
              finalize: () =>
                patchState(store, state => ({
                  isLoading: { ...state.isLoading, collectionUuids: false },
                })),
            })
          );
        })
      )
    ),
  }))
);

export type CollectionStore = InstanceType<typeof CollectionStore>;
