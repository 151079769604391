import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CollectionSection } from 'src/app/pages/collection/models/collection-section.type';
import { environment } from 'src/environments/environment';

import {
  Collection,
  CollectionUuids,
} from '@app/signal-store/collection/collection-state.interface';

@Injectable({ providedIn: 'root' })
export class CollectionApiService {
  constructor(private http: HttpClient) {}

  public addItem$(uuid: string, section: CollectionSection): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/v5/favorite`, {
      id: uuid,
      type: section,
    });
  }

  public getAllItemsUuids$(): Observable<CollectionUuids> {
    return this.http.get<CollectionUuids>(`${environment.apiUrl}/web/favorite`);
  }

  public getAllItems$(): Observable<Collection> {
    return this.http.get<Collection>(
      `${environment.apiUrl}/web/favorite?body=true`
    );
  }

  public deleteItem$(
    uuid: string,
    section: CollectionSection
  ): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/v5/favorite`, {
      body: {
        id: uuid,
        type: section,
      },
    });
  }
}
